<template>
  <div>
    <div v-if='speaker.educationalBackground'
      class='standard-transition flex flex-row justify-start items-start gap-x-8 bg-gray-50 px-2 py-4 mb-4 rounded text-sm'>
      <div class='w-1/4 flex-grow-0 flex-shrink-0'>
        <div :style='themeTextColorStyle'>Educational Background</div>
      </div>
      <div>
        <div class='text-gray-600 whitespace-pre-line'>{{ speaker.educationalBackground }}</div>
      </div>
    </div>
    <div v-if='speaker.workExperience' 
      class='standard-transition flex flex-row justify-start items-start gap-x-8 bg-gray-50 px-2 py-4 mb-4 rounded text-sm'>
      <div class='w-1/4 flex-grow-0 flex-shrink-0'>
        <div :style='themeTextColorStyle'>Work Experience</div>
      </div>
      <div>
        <div class='text-gray-600 whitespace-pre-line' v-html='speaker.workExperience'></div>
      </div>
    </div>
    <div v-if='speaker.publications'
      class='standard-transition flex flex-row justify-start items-start gap-x-8 bg-gray-50 px-2 py-4 mb-4 rounded text-sm'>
      <div class='w-1/4 flex-grow-0 flex-shrink-0'>
        <div :style='themeTextColorStyle'>Publications</div>
      </div>
      <div>
        <div class='text-gray-600 whitespace-pre-line' v-html='speaker.publications'></div>
      </div>
    </div>
    <div v-if='speaker.awards'
      class='standard-transition flex flex-row justify-start items-start gap-x-8 bg-gray-50 px-2 py-4 mb-4 rounded text-sm'>
      <div class='w-1/4 flex-grow-0 flex-shrink-0'>
        <div :style='themeTextColorStyle'>Awards</div>
      </div>
      <div>
        <div class='text-gray-600 whitespace-pre-line' v-html='speaker.awards'></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SpeakerCurriculumVitae',
  props: [
    'speaker'
  ],
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  },  
  methods: {
  },
}
</script>
