<template>
  <div class='py-12 px-4 overflow-y-auto'>
    <div class='text-xs mb-2' v-if='invitedByOrganization'>
      <span class='text-gray-500 uppercase'>Invited by:</span> 
      <span 
        class='uppercase font-semibold inline-block ml-1'
        :style='themeTextColorStyle'>
        {{ invitedByOrganization }}
      </span>
    </div>
    <div class='flex flex-row justify-start gap-x-8 mb-8'>
      <div v-if='showImage && speaker.imageUrl'
        class='block w-1/4 h-auto rounded-l-md lg:rounded-bl-none lg:rounded-t-lg flex-shrink-0'>
        <img :src='speaker.imageUrl'
          class='object-cover w-full lg:w-48 h-auto'
          @error='hideSpeakerImageIfNotAvailable'>
      </div>
      <div class='text-sm'>
        <div class='font-semibold uppercase text-2xl text-gray-900'>{{ speaker.name }}</div>
        <div class='text-gray-600'>{{ speaker.affiliation }}</div>
        <div class='text-gray-600'>{{ speaker.country }}</div>
        <a v-if='speaker.cvUrl'
          :href='speaker.cvUrl'
          class='inline-block py-1 w-24 rounded mt-2 mx-1 uppercase text-center border border-gray-300 hover:border-gray-600 hover:shadow-md cursor-pointer' 
          target='_blank'>
          {{speakerCvLinkUrlTitle}}
        </a>
        <div class='text-xs text-gray-500 mt-2 pr-2'>{{ speaker.intro }}</div>
      </div>
    </div>
    <div  v-if='showingEventId === 39 && hasSpeakerCvData'>
      <div class='flex flex-row justify-start gap-x-2 mb-4'>
        <div class='speaker-content-selector'
          :class='speakerContentSelectorActiveClass("sessions")'
          @click='updateSelectedSpeakerContent("sessions")'>
          Sessions
        </div>
        <div class='speaker-content-selector'
          :class='speakerContentSelectorActiveClass("cv")'
          @click='updateSelectedSpeakerContent("cv")'>
          CV
        </div>
      </div>
      <Speaker-curriculum-vitae
        v-if="selectedSpeakerContent === 'cv'"
        :speaker='speaker' />
      <div v-else-if="selectedSpeakerContent === 'sessions'">
        <speaker-sessions-by-role :sessions='filteredSessions' />
      </div>
    </div>
    <div  v-else>
      <h1 class='uppercase font-semibold'>Sessions</h1>
      <speaker-sessions-by-role :sessions='filteredSessions' />
    </div>
  </div>
</template>

<script>
import SpeakerSessionsByRole from '@/components/speakers/SpeakerSessionsByRole.vue'
import SpeakerCurriculumVitae from '@/components/speakers/SpeakerCurriculumVitae.vue'
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'SpeakerDetails',
  components: {
    SpeakerSessionsByRole,
    SpeakerCurriculumVitae
  },
  props: {
    detailsItemId: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      selectedSpeakerContent: 'sessions',
      showImage: true,
      speaker: {},
      speakerRoles: [
        'chair',
        'moderator',
        'speaker',
        'panelist',
        'panel',
        'judge',
      ],
    }
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
      'speakerCvLinkUrlTitle',
      'showingEventId',
    ]),
    filteredSessions () {
      let sessions = (this.speaker.listOfSessions) ? cloneDeep(this.speaker.listOfSessions.filter(session => this.speakerRoles.includes(session.role.toLowerCase()))) : []
      return sessions.sort((a, b) => new Date(a.sessionScheduledStartTime) - new Date(b.sessionScheduledStartTime))
    },
    invitedByOrganization () {
      let filterTagInvitedBy = (this.speaker && this.speaker.filterTags) ? this.speaker.filterTags.find(tag => tag.name == 'Inviting Organization') : null
      return (filterTagInvitedBy) ? filterTagInvitedBy.list.join(', ') : ''
    },
    hasSpeakerCvData() {
      return this.speaker.educationalBackground ||
        this.speaker.workExperience ||
        this.speaker.publications ||
        this.speaker.awards
    },
  },  
  watch: {
    detailsItemId: {
      handler: function (newVal) {
        if (newVal) {
          this.getSpeakerDetails(newVal).then(resp => {
            this.speaker = resp
          })
        } else {
          this.speaker = {}
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('speakers', [
      'getSpeakerDetails',
    ]),
    hideSpeakerImageIfNotAvailable () {
      this.showImage = false
    },
    speakerContentSelectorActiveClass (speakerContent) {
      return (this.selectedSpeakerContent === speakerContent) ? 'is_active' : ''
    },
    updateSelectedSpeakerContent(speakerContent) {
      this.selectedSpeakerContent = speakerContent
    },
  },
}
</script>


<style type='scss' scoped>
.speaker-content-selector {
  @apply border-b-2 text-center px-4 py-2 text-sm cursor-pointer;
}

.speaker-content-selector.is_active {
  font-weight: 600;
  border-color: var(--eventMainColor);
}

@media (min-width: 1024px) {
  .speaker-content-selector:hover {
    border-color: var(--eventMainColor);
  }
}
</style>
